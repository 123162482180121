<template>
  <div>
    <h1>
      {{ $t("survey.description") }} <help>{{ $t("survey.info") }}</help>
    </h1>
    <calendarSurvey />
  </div>
</template>
<script>
export default {
  components: {
    calendarSurvey() {
      return import("@/components/business/survey/index.vue");
    }
  },
  props: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>
<style lang="sass" scoped></style>
